@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pacifico&family=Whisper&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@layer utilities {
  body {
    background: linear-gradient(to right, black 50%, white 50%);
    font-family: "Assistant", sans-serif;
  }
}

@layer components {
  .navbar-home {
    @apply m-auto sticky top-0 z-50 lg:mt-[12rem] text-white lg:w-[80%] lg:mx-auto flex justify-between items-end py-5 lg:py-3 pr-8 tracking-widest bg-black lg:border-b-2 lg:border-[#F7F7F7] font-assistant;
  }
  .navbar-other {
    @apply m-auto sticky top-0 z-50 mt-0 text-white flex justify-between items-end pr-8 py-5 lg:py-3  tracking-widest bg-black font-assistant;
  }
}

.swiper-pagination-bullet {
  background-color: black;
}

.swiper-button-prev,
.swiper-button-next {
  color: black;
  scale: 0.9;
}

.vertical-timeline-element-date div {
  font-weight: 800;
}

.vertical-timeline-element-content p {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content {
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 16px;
    font-size: 1rem;
  }
}

